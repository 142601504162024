.container {
  padding-top: 125px;
  max-width: 100% !important;
  position: relative;
  text-align: center;
}

#sideNavbarContainer {
  padding-top: 0px !important;

}

#leftButtons {
  padding-left: 0px !important;
}
#leftButtons a {
  margin-left: 0px !important;
}

.settings-outer {
  height: 90vh;
}
.container-outer {
  height: 90vh;
  min-height: 760px;
}

.oidc-error {
  text-align: center;
  height: 100%;
}

.oidc-error img {
	margin-top: 25px;
}

footer {
  position: absolute;
  bottom: 0px;
  z-index: -1;
}

#root {
  /* height:calc(100vh - 92px - 92px); */
  min-height: 100vh;
  position: relative;
  padding-bottom: 92px;
}

#mainNavBar {
  position: absolute !important;
}

#divOr {
  text-align: center;
}

.instructionHeader{
  text-align: center;
  margin-bottom: 30px;
}

.instructionHeader p {
  color: #004F9D;
  font-family: Roboto, 'sans-serif';
  font-size: 20px;
  letter-spacing: 0px;
  margin-bottom: 0%;
  font-weight: 600;
  text-align: center !important;
}

.instructionHeader hr {
  border: 2px solid #3564BA;
  opacity: 1;
  width: 90px;
  margin: 0 auto;
  margin-top: 15px;
}

.divForm p {
  text-align: center;
  margin-bottom: 5px;
}

.p-center {
	text-align: center !important;
}

.divForm{
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}


.divForm .form-control {
  border: 1px solid #004F9D;
  width: 100% !important;
}

.divForm input[type=text]  {
  cursor: text;
}


.dob-fields {
	margin-bottom: 1rem;
}

.dob-fields .form-group {
	display: inline-block;
	width: 30%;
	margin-bottom: 0px;
}

.dob-fields .form-group:nth-child(2) {
	margin-left: 2%;
	margin-right: 2%;
}

.dob-fields .form-group:nth-child(3) {
	width: 36%;
}

.dob-fields .form-group input {
	margin-bottom: 0px;
}

.dob-fields .form-group .is-invalid ~ .invalid-feedback {
	display: none;
}

.dob-fields .form-group .form-control.is-invalid {
	padding-right: .75rem;
}

#divLoginButton {
  display: inline-flex;
}

#ddlUserType {
  width: 100%;
}
.auth-button  {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background-color: #008EFF !important;
  border-radius: 6px !important;
  color: white !important;
  min-height: 41px !important;
  width: 320px !important;
  padding: 0px !important;
  height: 41px !important;
}

.oidcLoginButton {
  height: 40px;
  margin-top: 10px;
  width:100%;
  background-color: white !important;
  border-radius: 6px !important;
  border: 1px #0a58ca;
  margin-bottom: 10px;
  /* text-align: left !important;
  padding-left: 50px !important; */
}

.oidcLoginButton div.spinner-border {
	width: 1.5rem;
	height: 1.5rem;
}

.oidcLoginButton img {
   height: 26px;
}

.oidcLoginButton:hover{
  background-color: whitesmoke !important;
}

.oidc-button-text {
	color: rgb(68, 68, 68);
	padding-left: 10px;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	line-height: 18px;
	vertical-align: middle;
}

.user-type-selection-text {
	color: black;
	padding-left: 10px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	line-height: 16px;
	vertical-align: middle;
}

.auth-button .spinner-border {
	color: white !important;
	height: 1rem !important;
	width: 1rem !important;
}

.auth-button:hover{
  background-color: #004F9D !important;

}

#divHomeIcon {
  background-image: url(./resources/home_FILL0_wght400_GRAD0_opsz48.svg);
  width: 32px;
  height: 36px;
  background-repeat: no-repeat;
  margin-right: 20px;
}

#divRightHeaderElement {
  display: inline-flex;
  cursor: pointer;
}

#lblHomeButton{
  color: #004F9D;
  font-family: Roboto 'sans-serif';
  font-size: 20px;
  letter-spacing: 0px;
  margin-bottom: 0%;
  font-weight: 600;
  margin-top: 5px;
}

.PasswordWrapper {
  width:100%;
  display: inline-block;
  position: relative;
}

.PasswordWrapper > input {
  width:100%;
  background-color: transparent;
  position: absolute;
}

.Visibility-Off {
  background-image: url(./resources/Visibility\ Off.svg);
  background-repeat: no-repeat;
  width: 36px;
  height: 32.768px;
  cursor: pointer;
  right: 5px;
  top: 7px;
  position: absolute;
}

.Visibility-On {
  background-image: url(./resources/visibility_FILL0_wght400_GRAD0_opsz48.svg);
  background-repeat: no-repeat;
  width: 36px;
  height: 25px;
  cursor: pointer;
  right: 5px;
  top: 9px;
  position: absolute;
}

.Errors {
  color: #dc3545;
  font-size: 0.875em;
  margin: 10px auto;
}

.errorImage {
	width: 145px;
}

.divInaccessible {
  margin-top: 50%;
  width: 320px;
}

.container a {
  text-decoration: none;
  color: #004F9D;
  font-size: 1rem;
}

.container a:hover{
  cursor: pointer;
}

.mfa-option {
	margin-left: 15px;
	margin-bottom: 15px !important;
	text-align: left;
}

.security-code-container input {
	text-align: center;
}

.security-code-filled input {
	letter-spacing: 15px;
}

.settings-text-box {
  width: 360px !important;
  height: 38px !important;
}
#LogoutLink {
  display: flex;
  margin-top: 15px;
	height: 24px !important;
	background:url(resources/LogoutIcon.png) no-repeat 0px 0px;
	background-size: 24px 24px;
	text-decoration: none !important;
}
/* remove margin to center horizontally in smaller HeaderNavbar */
@media screen and (max-width: 992px) {
  #LogoutLink {
    margin-top: 0px;
  }
}

.logout-user {
	padding-left: 35px;
	font-family: Roboto !important;
	color:#004F9D !important;
	font-size: 16px !important;
}
.nav-item {
  text-align: left !important;
}
.button-settings {
  border-radius: 15px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  font-size: 12px !important;
  height: 29px !important;
  margin: 5px 5px 5px 0 !important;
  outline: none !important;
  text-align: center;
  min-width: 130px !important;
}
.button-settings:disabled {
  background-color: #7e7e7e !important;
  border: none;
  opacity: 100 !important;
  color: white !important;
}
.button-settings-sub {
  border-radius: 15px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  font-size: 12px !important;
  height: 29px !important;
  margin-left: 15px !important;
  margin-bottom: 5px !important;
  outline: none !important;
  text-align: center;
  min-width: 100px !important;
}
.button-settings-sub:disabled {
  background-color: #7e7e7e !important;
  border: none;
  opacity: 100 !important;
}
.copy-test-label {
  text-align: center;
  font: normal normal normal 14px/22px Roboto;
  letter-spacing: 0px;
  color: #004F9D !important;
  opacity: 1;
  padding-left: 10px;
}
.copy-test-label-disabled {
  text-align: center;
  font: normal normal normal 14px/22px Roboto;
  letter-spacing: 0px;
  color: #7e7e7e !important;
  opacity: 1;
  padding-left: 10px;
}
.NavFooter-module_back__N4ujh {
  border-radius: 15px !important;
  background-color: #FFFFFF !important;
}
.NavFooter-module_back__N4ujh:hover {
  background-color: #008EFF !important;
}
.NavFooter-module_blue__h6oDp {
  border-radius: 15px !important;
  background-color: #008EFF !important;
  text-align: center !important;
  font: normal normal normal 22px Roboto !important;
  letter-spacing: 0px !important;
  color: #FFFFFF !important;
  opacity: 1 !important;
  padding-top: 10px !important;
}
.NavFooter-module_blue__h6oDp:hover {
  background-color: #004F9D !important;

}
.optional-label {
  color:#707070;
  font-size: 16px !important;
}

.toggle-header {
  padding-bottom: 1px !important;
  margin-bottom: 0px !important;
  font-size: 20px !important;
}

#MicrosoftFields {
  margin-bottom: 50px;
}

.under-construction {
  width: 100%;
}
.under-construction-inner {
  margin: auto;
  width: 225px;
  padding: 10px;
}

.robot-img {
  width: 110px;
  text-align: center;
  margin-left: 40px;
}
.code-text {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  font: normal normal bold 16px Roboto;
  letter-spacing: 0px;
}

.unlink-link {
  font: normal normal normal 16px Roboto;
  letter-spacing: 0px;
  color: #FF0900;
  margin-left: 15px;
  cursor: pointer;
}

.settings-nav {
  position: absolute;
  bottom: 75px;
  width: calc(100% - 100px);
}

#tenantGroup > div {
  display: inline-block !important;
}

.text-box-error {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  position: relative;
  bottom: 15px;
}
#testEmployee {
  padding-top: 15px;
  height: 15px;
}

.table-container {
  padding: 0 !important;
  margin: 10px 0;
  font-size: 14px;
  margin-bottom: 150px;
}

.table-container th {
  background-color: var(--primary-color-blue) !important;
  color: white !important;
}

.small-input {
  width: 200px !important;
}

.page-size-select > div.form-group {
  flex: 0 0 auto;
  width: auto;
  margin: 0;
  padding-left: 0;
}